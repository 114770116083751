export const newServiceContent = {
  replacement: {
    img: 'replacement.jpg',
    title: 'Замена трансформаторного масла в силовых трансформаторах - Промтех',
    description: 'Замена трансформаторного масла с гарантией качества для силовых трансформаторов. Профессиональные услуги по замене масла в трансформаторах, обеспечивающие надежность и долговечность оборудования.',
    h1: 'Замена трансформаторного масла',
    info1: `Замена масла в силовых трансформаторах проводится согласно регламенту с целью поддержания эффективности и безопасности оборудования.`,
    info2: 'Наша компания предлагает полный спектр услуг по замене масла с гарантией качества и надежности.',
    list: {
      title: 'Этапы проведения работ',
      info: 'Важны все этапы',
      steps: [
        'Слив отработанного масла',
        'Сушка и вакуумирование трансформатора <br/> (при необходимости)',
        'Заливка нового масла',
        'Дегазация и сушка нового масла',
        'ФХА и ХАРГ анализ масла'
      ]
    },
    final: {
      title: 'Необходимость замены',
      info: [
        `Замена трансформаторного масла необходима, когда оно теряет свои диэлектрические свойства, загрязняется влагой, твердыми частицами или продуктами окисления, а также при деградации его химического состава, что снижает эффективность охлаждения и изоляции`,
        'Кроме того, замена требуется, если масло содержит кислоты и другие агрессивные вещества, вызывающие коррозию внутренних компонентов трансформатора'
      ]
    },
    sameServices: {
      title: 'Похожие услуги',
      list: [
        {
          href: 'cleaning/',
          name: 'Очистка трансформаторного масла',
          img: 'cleaning.jpg'
      },
      {
        href: 'degasification/',
        name: 'Дегазация трансформаторного масла',
        img: 'degasification.jpg'
      },
      {
        href: 'regeneration/',
        name: 'Регенерация трансформаторного масла',
        img: 'regeneration.jpg'
      },
      ] 
    }
  },
  topping: {
    img: 'topping.jpg',
    title: 'Долив трансформаторного масла в силовых трансформаторах - Промтех',
    description: 'Профессиональный долив масла в трансформаторы для обеспечения стабильной и безопасной эксплуатации силовых установок. Надежные услуги поддержания оптимального уровня масла в вашем оборудовании.',
    h1: 'Долив трансформаторного масла',
    info1: `Долив масла в силовых трансформаторах проводится согласно регламенту с целью поддержания необходимого уровня масла в баке`,
    info2: 'Наша компания предлагает полный спектр услуг по заливке и доливу масла с гарантией качества и надежности',
    list: {
      title: 'Этапы проведения работ',
      info: 'Важны все этапы',
      steps: [
        'Заказ необходимого объема',
        'Транспортировка масла и установки',
        'Долив масла в трансформатор',
        'Дегазация и сушка масла',
        'ФХА и ХАРГ анализ масла',
      ]
    },
    final: {
      title: 'Необходимость долива',
      info: [
        `Долив трансформаторного масла необходим, когда уровень масла снижается из-за утечек, испарения или температурных изменений. Это также требуется при проведении технического обслуживания или ремонта, чтобы восстановить необходимый уровень масла и обеспечить надежную работу трансформатора`,
        'Регулярный долив в рамках профилактического обслуживания помогает поддерживать оптимальные рабочие характеристики трансформатора'
      ]
    },
    sameServices: {
      title: 'Похожие услуги',
      list: [
        {
          href: 'cleaning/',
          name: 'Очистка трансформаторного масла',
          img: 'cleaning.jpg'
      },
      {
        href: 'degasification/',
        name: 'Дегазация трансформаторного масла',
        img: 'degasification.jpg'
      },
      {
        href: 'regeneration/',
        name: 'Регенерация трансформаторного масла',
        img: 'regeneration.jpg'
      },
      ] 
    }
  },
  filling: {
    img: 'filling.jpg',
    title: 'Заливка трансформаторного масла в силовые трансформаторы - Промтех',
    description: 'Профессиональная заливка масла в трансформаторы с последующей осушкой и дегазацией, а также проведением анализов',
    h1: 'Заливка трансформаторного масла',
    info1: `Заливка масла при монтаже или по окончанию капитального ремонта силового трансформатора согласно регламенту`,
    info2: 'Наша компания предлагает полный спектр услуг по заливке масла с гарантией качества и надежности',
    list: {
      title: 'Этапы проведения работ',
      info: 'Важны все этапы',
      steps: [
        'Заказ необходимого объема (при необходимости)',
        'Транспортировка установки на объект',
        'Заливка масла в трансформатор',
        'Дегазация и сушка масла',
        'ФХА и ХАРГ анализ масла',
      ]
    },
    final: {
      title: 'Необходимость заливки',
      info: [
        `Заливка трансформаторного масла необходима при монтаже нового трансформатора для обеспечения его правильной работы и защиты от перегрева и изоляционных сбоев. Масло заливается в трансформатор под вакуумом, что обеспечивает надлежащее охлаждение и диэлектрическую изоляцию`,
        'Также проводится заливка после ремонта трансформатора, если масло было слито или заменено'
      ]
    },
    sameServices: {
      title: 'Похожие услуги',
      list: [
        {
          href: 'cleaning/',
          name: 'Очистка трансформаторного масла',
          img: 'cleaning.jpg'
      },
      {
        href: 'degasification/',
        name: 'Дегазация трансформаторного масла',
        img: 'degasification.jpg'
      },
      {
        href: 'regeneration/',
        name: 'Регенерация трансформаторного масла',
        img: 'regeneration.jpg'
      },
      ] 
    }
  },


  to: {
    img: "to.jpg",
    title: "Техническое обслуживание трансформаторов - Промтех",
    description: "Комплексное техническое обслуживание трансформаторов, включая диагностику, очистку и замену компонентов.",
    h1: "Техническое обслуживание трансформаторов",
    info1: "Проведение регулярного технического обслуживания силовых трансформаторов для обеспечения их надежной и долгосрочной работы.",
    info2: "Наша компания предлагает полный спектр услуг по техническому обслуживанию трансформаторов с гарантией качества и профессионализма.",
    list: {
        title: "Этапы проведения работ",
        info: "Все этапы важны для поддержания работоспособности трансформатора",
        steps: [
            "Планирование и согласование",
            "Диагностика состояния трансформатора",
            "Очистка и дегазация масла",
            "Проверка и очистка радиаторов и других компонентов",
            "Замена изношенных и поврежденных деталей",
            "Проведение анализов и тестов на работоспособность"
        ]
    },
    final: {
        title: "Необходимость технического обслуживания",
        info: [
            "Регулярное техническое обслуживание трансформаторов необходимо для обеспечения их надежной работы, предотвращения аварий и увеличения срока службы оборудования.",
            "Проведение диагностики и профилактических работ позволяет своевременно выявлять и устранять потенциальные неисправности, что снижает риск незапланированных простоев и повреждений."
        ]
    },
    sameServices: {
        title: "Похожие услуги",
        list: [
            {
                href: "current/",
                name: "Ремонт силовых трансформаторов",
                img: "current.jpg"
            },
            {
                href: "capital/",
                name: "Капитальный ремонт трансформаторов",
                img: "capital.jpg"
            },
            {
                href: "install/",
                name: "Монтаж силовых трансформаторов",
                img: "install.jpg"
            }
        ]
    }
  },
  current: {
    "img": "replacement.jpg",
    "title": "Ремонт силовых трансформаторов - Промтех",
    "description": "Профессиональный ремонт силовых трансформаторов для восстановления их работоспособности и продления срока службы.",
    "h1": "Ремонт силовых трансформаторов",
    "info1": 'Компания "Промтех" выполняет <strong>текущий</strong>, <strong>капитальный</strong> и <strong>аварийный</strong> ремонт силовых масляных трансформаторов с классом напряжения от 10 кВ до 330 кВ.',
    "info2": "Предлагаем широкий спектр услуг по восстановлению и поддержанию свойств электрооборудования с гарантией.",
    "list": {
        "title": "Этапы проведения работ",
        "info": "Каждый этап важен для обеспечения эффективного ремонта",
        "steps": [
            "Диагностика неисправностей и оценка состояния оборудования",
            "Разборка и очистка трансформатора",
            "Замена поврежденных и изношенных деталей",
            "Проверка электрических соединений и контактов",
            "Замена или ремонт изоляции",
            "Сборка и тестирование трансформатора"
        ]
    },
    "final": {
        "title": "Необходимость проведения ремонта",
        "info": [
            "Ремонт силовых трансформаторов – это сложный и ответственный процесс, требующий высокой квалификации специалистов, точного соблюдения технологий и применения современных методов диагностики. Своевременное обслуживание и качественный ремонт позволяют продлить срок службы оборудования, повысить его надежность и предотвратить возможные аварийные ситуации.",
            "Все виды ремонта проводятся в соответствии с техническими регламентами и стандартами, с использованием современных технологий и качественных материалов. "
        ]
    },
    "sameServices": {
        "title": "Похожие услуги",
        list: [
          {
            href: "install/",
            name: "Монтаж силовых трансформаторов",
            img: "install.jpg"
          },
          {
              href: "../",
              name: "Дегазация трансформаторного масла",
              img: "to.jpg"
          },
          {
              href: "../analysis/",
              name: "ФХА и ХАРГ трансформаторного масла",
              img: "chemistry.jpg"
          }
      ]
    }
  },
  capital: {
    "img": "capital.jpg",
    "title": "Капитальный ремонт трансформаторов - Промтех",
    "description": "Комплексный капитальный ремонт трансформаторов для полного восстановления их работоспособности и увеличения срока службы.",
    "h1": "Капитальный ремонт трансформаторов",
    "info1": "Капитальный ремонт трансформаторов для устранения серьезных неисправностей и модернизации оборудования.",
    "info2": "Наша компания предлагает полный спектр услуг по капитальному ремонту трансформаторов с гарантией качества и долговечности.",
    "list": {
        "title": "Этапы проведения работ",
        "info": "Каждый этап важен для обеспечения надежного капитального ремонта",
        "steps": [
            "Комплексная диагностика и оценка состояния оборудования",
            "Разборка трансформатора",
            "Очистка и дегазация трансформаторного масла",
            "Замена всех изношенных и поврежденных деталей",
            "Ремонт или замена обмоток и сердечника",
            "Восстановление или замена изоляции",
            "Сборка и проведение испытаний"
        ]
    },
    "final": {
        "title": "Необходимость капитального ремонта",
        "info": [
            "Капитальный ремонт трансформатора необходим для устранения серьезных неисправностей, которые невозможно устранить в рамках текущего ремонта.",
            "Проведение капитального ремонта позволяет значительно продлить срок службы оборудования и повысить его эффективность и надежность."
        ]
    },
    "sameServices": {
        "title": "Похожие услуги",
        list: [
          {
            href: "to/",
            name: "Техобслуживание трансформаторов",
            img: "to.jpg"
          },
          {
              href: "current/",
              name: "Ремонт силовых трансформаторов",
              img: "current.jpg"
          },
          {
              href: "install/",
              name: "Монтаж силовых трансформаторов",
              img: "install.jpg"
          }
      ]
    }
},
install: {
  "img": "install.jpg",
  "title": "Монтаж силовых трансформаторов - Промтех",
  "description": "Профессиональный Монтаж силовых трансформаторов с обеспечением качественной и надежной работы оборудования.",
  "h1": "Монтаж силовых трансформаторов",
  "info1": "Профессиональный Монтаж силовых трансформаторов для обеспечения их правильной работы и безопасности.",
  "info2": "Наша компания предлагает полный спектр услуг по монтажу трансформаторов с гарантией качества и соблюдением всех технических норм.",
  "list": {
      "title": "Этапы проведения работ",
      "info": "Каждый этап важен для обеспечения правильного монтажа",
      "steps": [
          "Проектирование и планирование работ",
          "Подготовка площадки для установки",
          "Доставка трансформатора на объект",
          "Установка и закрепление трансформатора",
          "Подключение трансформатора к сети",
          "Проведение испытаний и проверка работоспособности"
      ]
  },
  "final": {
      "title": "Необходимость монтажа",
      "info": [
          "Монтаж трансформатора необходим для обеспечения его правильной и безопасной работы. Это включает установку трансформатора на подготовленную площадку и его подключение к электрической сети.",
          "Профессиональный монтаж гарантирует, что оборудование будет работать эффективно и надежно, минимизируя риск аварий и неисправностей."
      ]
  },
  "sameServices": {
      "title": "Похожие услуги",
      list: [
        {
          href: "to/",
          name: "Техобслуживание трансформаторов",
          img: "to.jpg"
        },
        {
            href: "current/",
            name: "Ремонт силовых трансформаторов",
            img: "current.jpg"
        },
        {
            href: "capital/",
            name: "Капитальный ремонт трансформаторов",
            img: "capital.jpg"
        },
       
    ]
  }
}






}