import React, { useState } from "react";
import "./EmergencyRepair.scss";

const EmergencyRepair = ({ title, description, image, tasks }) => {
  const [showAll, setShowAll] = useState(false);
  const visibleTasks = showAll ? tasks : tasks.slice(0, 4); // Показываем 4 или все

  return (
    <div className="repair-container">
      <div className="repair-content">
        <div className="repair-text">
          <h2>{title}</h2>
          <p className="repair-description">{description}</p>
          <ul className="repair-list">
            {visibleTasks.map((task, index) => (
              <li key={index}>— {task}</li>
            ))}
          </ul>
          {!showAll && tasks.length > 4 && (
            <button className="show-more-btn" onClick={() => setShowAll(true)}>
              Посмотреть еще ↓
            </button>
          )}
        </div>
        <div className="repair-image">
          <img src={image} alt={title} />
        </div>
      </div>
    </div>
  );
};

export default EmergencyRepair;
