const repairData = [
    {
      id: 1,
      title: "Текущий ремонт трансформаторов",
      description:
        "Этот вид ремонта проводится без извлечения активной части и выполняется на месте эксплуатации. Основная задача – устранение мелких дефектов, предупреждение серьезных неисправностей и продление срока службы оборудования. Рекомендуемая периодичность – не реже одного раза в 3 года, а для трансформаторов 35/6-10 кВ – ежегодно.",
      image: "/img/current.jpg",
      tasks: [
        "Проверка общего состояния трансформатора",
        "Очистка корпуса от загрязнений и следов масла",
        "Отбор проб масла для лабораторного анализа",
        "Долив или полная замена трансформаторного масла",
        "Проверка контактных соединений и их подтяжка",
        "Обслуживание системы охлаждения (чистка радиаторов)",
        "Контроль состояния вводов и заземляющих соединений",
        "Замена или восстановление уплотнителей",
        "Проверка устройств регулировки напряжения",
        "Испытания изоляции и измерение коэффициента трансформации",
      ],
    },
    {
      id: 2,
      title: "Капитальный ремонт 1-й категории",
      description:
        "Капитальный ремонт 1-й категории включает выемку активной части трансформатора без ее разборки. Этот процесс проводится на специализированных предприятиях и предназначен для восстановления рабочих характеристик трансформатора после длительной эксплуатации.",
      image: "/img/capital1.jpg",
      tasks: [
        "Демонтаж трансформатора и отключение от сети",
        "Слив трансформаторного масла",
        "Разборка корпуса и снятие крышки",
        "Извлечение активной части и ее очистка",
        "Диагностика состояния обмоток, изоляции и магнитопровода",
        "Проверка геометрии обмоток и крепежных элементов",
        "Ремонт или замена уплотнителей и вводов",
        "Регенерация или замена масла",
        "Финальные электрические испытания",
        "Покраска корпуса и нанесение защитных покрытий",
      ],
    },
    {
      id: 3,
      title: "Капитальный ремонт 2-й категории",
      description:
        "Капитальный ремонт 2-й категории является наиболее сложным процессом и включает полную разборку активной части. Он выполняется при значительных повреждениях обмоток, изоляции или магнитопровода.",
      image: "/img/capital2.jpg",
      tasks: [
        "Полный демонтаж трансформатора",
        "Разборка бака и слив масла",
        "Извлечение активной части и ее диагностика",
        "Разборка активной части",
        "Снятие переключателя напряжения",
        "Размотка и удаление старых обмоток",
        "Очистка и восстановление магнитопровода",
        "Намотка новых катушек ВН и НН",
        "Установка новых изоляционных компонентов",
        "Сборка активной части и монтаж в бак",
        "Сушка активной части и заправка маслом",
        "Финальные испытания и тестирование оборудования",
      ],
    },
  ];
  
  export default repairData;
  