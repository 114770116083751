import React, {useEffect} from 'react'
import './footer.scss'
import logo from '../../../media/img/Promtech-logo-short.png'
import {Link} from "react-router-dom"
import { pageTargets,targetFunc } from './../../../ya-metrics';





export default function Footer(props) {


useEffect(() => {
    document.querySelector('.company').addEventListener('mousedown', ()=>{targetFunc(pageTargets.footer.company)});
    document.querySelector('.footer__email').addEventListener('mousedown', ()=>{targetFunc(pageTargets.footer.mail)});
    document.querySelector('.footer__phone').addEventListener('mousedown', ()=>{targetFunc(pageTargets.footer.phone)});
}, [])

const isPrerender = navigator.userAgent === "ReactSnap";


  return (
    <>
    <div class="footer">
        <div class="footer__column_left">
            <div class="footer__logo">
                <Link to="/"><img src={logo} alt="" class="footer__logo_img"></img></Link>
            </div>
            <div class="footer__copyright">
                <div>©2025</div>
                <div className='company'>ООО "Компания ПРОМТЕХ"</div>
            </div>
        </div>
        <div class="footer__column_right">
            <div class="footer__form" onClick={() => {
                targetFunc(pageTargets.footer.formOpen);
                props.showForm()
            }}>Написать нам</div>
            <div class="footer__email">info@promtech-company.ru</div>
            <div class="footer__phone"> {props.phoneNumber}</div>
        </div>
    </div>
    {!isPrerender ? 
    <div className="mobile-footer">
        <div className="info">
            <div className="company">ООО "Компания ПРОМТЕХ"<span>©2025</span></div>
            <div className="contacts">
                <div className="phone">{props.phoneNumber}</div>
                <div className="email">info@promtech-company.ru</div>
            </div>
        </div>
        <div className="button" onClick={()=>{
            props.setOpenerType('form');
        }}>Написать</div>
    </div>
    : <></>}
    </>
  )
}
